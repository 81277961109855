<template>
  <div class="header-title">
    <h1>Service We Provide</h1>
    <p>We build product close to our heart. We make your idea to reality and make your dream successful with awesome experience.</p>
  </div>
  <div class="skills_grid">
    <div class="skills-wrapper">
      <div class="skills_grid-card">
        <div class="skills_grid-card-icon">
          <img src="../assets/img/icons/website.svg" alt="">
        </div>
        <h4 class="skills_grid-card-title">Web 2.0 & Web 3.0</h4>
        <ul class="skills_grid-card-list">
          <li>- UX/UI</li>
          <li>- Frontend / Backend development</li>
          <li>- DeFI</li>
          <li>- E-commerce Solution</li>
          <li>- NFT</li>
        </ul>
      </div>
      <div class="skills_grid-card">
        <div class="skills_grid-card-icon">
          <img src="../assets/img/icons/mobile.svg" alt="">
        </div>
        <h4 class="skills_grid-card-title">Mobile</h4>
        <ul class="skills_grid-card-list">
          <li>- Native apps iOS & Android</li>
          <li>- Progressive web apps</li>
          <li>- Cross-Platform mobile solutions</li>
          <li>- Hybrid app development</li>
        </ul>
      </div>
      <div class="skills_grid-card">
        <div class="skills_grid-card-icon">
          <img src="../assets/img/icons/machine-learning.svg" alt="">
        </div>
        <h4 class="skills_grid-card-title">AI & ML</h4>
        <ul class="skills_grid-card-list">
          <li>- Machine Learning and Deep Learning</li>
          <li>- Digital Virtual Agents and Chatbots</li>
          <li>- Custom AI Tools</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillsBlock',
  components: [
  ],
  data () {
    return {
    }
  }
}
</script>
