<template>
    <div class="main-wrap">
      <div class="container-main">
        <h1 class="container-main_title">Shelit Agency</h1>
        <h3 class="container-second_title">
          We create <b>digital services</b> of the <b>future</b>
        </h3>
      </div>
      <div class="numbers-wrap">
        <div class="numbers-agency">
          <div class="numbers-agency_item">
            <h2>20+</h2>
            <p>digital projects</p>
          </div>
          <div class="numbers-agency_item">
            <h2>12</h2>
            <p>professionals</p>
          </div>
          <div class="numbers-agency_item">
            <h2>3</h2>
            <p>year expersiense</p>
          </div>
        </div>
      </div>
    </div>  
</template>
<script>
export default {
  name: 'MainSlide',
  components: [
  ],
  data () {
    return {
      mainTitle: true
    }
  }
}
</script>