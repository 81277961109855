<template>
    <div class="feedback-main">
        <div class="wrapper">
            <div class="feedback-main_txt">
              <div class="header-title">
                Do you have any project on mind 
              </div>
              <p>Write to us and we will discuss your idea with you. Entrust your idea to us and we will turn it into reality.</p>
            </div>
            <div class="feedback-main_form">
              <div class="wrap-contact">
                <form class="contact2-form validate-form" @submit.prevent="submitForm" ref="form">
                  <div class="wrap-input validate-input" data-validate="Name is required">
                    <input class="input" :class="this.form.name !== '' ? 'has-val' : ''" type="text" name="name" v-model="this.form.name" ref="name">
                    <span class="focus-input" :class="this.form.name !== '' ? 'has-val' : ''" data-placeholder="NAME"></span>
                  </div>
                  <div class="wrap-input validate-input" data-validate="Valid email is required: you@shelit.agency">
                    <input class="input" :class="this.form.email !== '' ? 'has-val' : ''" type="email" name="email"
                     v-model="this.form.email" ref="email">
                    <span class="focus-input" :class="this.form.email !== '' ? 'has-val' : ''" data-placeholder="EMAIL"></span>
                  </div>
                  <div class="wrap-input validate-input" data-validate="Message is required">
                    <textarea class="input" :class="this.form.message !== '' ? 'has-val' : ''" name="message"  v-model="this.form.message" ref="message"></textarea>
                    <span class="focus-input" :class="this.form.message !== '' ? 'has-val' : ''" data-placeholder="MESSAGE"></span>
                  </div>
                  <div class="wrap-btn">
                    <button
                      v-if="!this.result.bool"
                      v-on:click="submit" class="btn-primary">Send</button>
                      <p v-if="this.result.message">{{ this.result.message }}</p>
                  </div>
                </form>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'FeedbackBlock',
  data (){
    return {
      form: {
        name : '',
        email : '',
        message: '',
      },
      result: {
        bool: false,
        message: '',
      }
    }
  },
  methods: {
    submitForm() {
      // axios
      axios({
        method: "post",
        url: "https://mail.shelit.agency/",
        data: this.form,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        this.result.bool = true;
        this.result.message = "Your message has been sent. Thank you!";
        this.$refs.form.reset()
      }).catch(() => {
        this.result.message = "An error has occurred, please try again.";
        this.$refs.form.reset()
      });
    }
  }
}
</script>
