<template>
    <div class="how-work-main">
        <div class="how-work-main_img">
            <img src="../assets/img/undraw_coffee_with_friends_3cbj.svg" alt="">
        </div>
        <div class="how-work-wrapper">
            <div class="header-title">
                <h1>About</h1>
                <p>Young. Ambitious. Energetic.</p>
            </div>
            <div class="how-work">
                <div class="how-work_card">
                    <h2 class="how-work_header">What we do</h2>
                    <div class="how-work_txt">
                        <p>We build custom digital products. Our highly qualified team can help you achieve your goals.</p>
                    </div>
                </div>
                <div class="how-work_card">
                    <h2 class="how-work_header">From $50 per hour</h2>
                    <div class="how-work_txt">
                        <p>We aim to grow our business exponentially. We collect cross-functional teams for each project.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
  name: 'HowWork',
}
</script>