<template>
  <div class="wrapper">
    <header>
      <div class="logo">
        <img src="./assets/img/logo.png" alt="Logo Shelit Agency">
      </div>
      <div class="header-btn_bar">
        <a class="btn-primary" target="_blank" href="https://drive.google.com/file/d/189PTte8Gq2SVFXZebGRoWIlyP1nKweqG/view?usp=share_link">presentation</a>
        <a class="btn-primary" href="mailto:were@shelit.agency">write to us</a>
      </div>
    </header>
    <MainSLide></MainSLide>
    <SkillsBlock></SkillsBlock>
    <HowWork></HowWork>
  </div>
  <FeedbackBlock></FeedbackBlock>
  <footer>
    <div class="wrapper">
      <div class="footer-info">
        <h3>Get in Touch</h3>
        <p class="footer-touch_txt">
          Write to us if you want to cooperate with us, or just talk about the future 
          <a href="mailto:were@shelit.agency">were@shelit.agency</a>
        </p>
        <p class="copyright">LLC SHELTER IT, 19-22 ©</p>
      </div>
      <div class="footer-contact">
        <h3>Follow us</h3>
        <a href="https://www.instagram.com/shelit.agency/" target="_blank">INSTAGRAM</a>
      </div>
    </div>
  </footer>
</template>

<script>
import '@/assets/styles/main.css';
import MainSLide from '@/components/MainSlide.vue'
import SkillsBlock from '@/components/SkillsBlock.vue'
import HowWork from './components/HowWork.vue';
import FeedbackBlock from './components/FeedbackBlock.vue';

export default {
    name: "App",
    components: {
        MainSLide,
        SkillsBlock,
        HowWork,
        FeedbackBlock
    },
    data() {
        return {
            words: ["Metaverse & Web3", "Crypto & Nft"]
        };
    },
}
</script>